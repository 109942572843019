/* CustomForm.css */
.custom-form-container {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-form-header {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
}

.custom-form-group {
    margin-bottom: 15px;
}

.custom-form-label {
    display: block;
    margin-bottom: 5px;
    color: #666;
}

.custom-form-input,
.custom-form-select,
.custom-form-textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box; /* Added this to fix padding and border issue */
}

.custom-form-input:focus,
.custom-form-select:focus,
.custom-form-textarea:focus {
    outline: none;
    border-color: #7aacfe;
    box-shadow: 0 0 3px #add8e6;
}

.custom-form-button {
    padding: 10px 15px;
    color: white;
    background-color: #7aacfe;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.custom-form-button:hover {
    background-color: #659eef;
}

.custom-form-feedback {
    color: #ff6b6b;
    font-size: 14px;
}

/* Specific styling can be added for invalid inputs or other form states */
.custom-form-input.is-invalid,
.custom-form-select.is-invalid,
.custom-form-textarea.is-invalid {
    border-color: #ff6b6b;
}
